import * as React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import TableLoader from '../GlobalComponents/TableLoader';

interface EmitterHash {
    worker: string;
    hash15Min: number;
    hash24Hr: number;
    status: 'ONLINE' | 'OFFLINE';
    coin: string | null;
}

type EmitterHashKey = 'worker' | 'hash15Min' | 'hash24Hr' | 'status' | 'coin';

export default function EmitterHashList() {
    const [rows, setRows] = useState<EmitterHash[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<EmitterHashKey>('worker');
    const [hashType, setHashType] = useState<'hash15Min' | 'hash24Hr'>('hash15Min');
    const [coinFilter, setCoinFilter] = useState<string>('');
    const [uniqueCoins, setUniqueCoins] = useState<string[]>([]);

    const handleRequestSort = (property: EmitterHashKey) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array: EmitterHash[], comparator: (a: EmitterHash, b: EmitterHash) => number) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [EmitterHash, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order: 'asc' | 'desc', orderBy: EmitterHashKey) => {
        return (a: EmitterHash, b: EmitterHash) => {

            // @ts-ignore
            if (b[orderBy] < a[orderBy]) return order === 'desc' ? -1 : 1;
            // @ts-ignore
            if (b[orderBy] > a[orderBy]) return order === 'desc' ? 1 : -1;
            return 0;
        };
    };

    useEffect(() => {
        fetchEmitterHashList();
    }, []);

    const fetchEmitterHashList = () => {
        axios.get(API_PATH + '/monitor/emitterhash', getAxiosConfig())
            .then(response => {
                const data: EmitterHash[] = response.data;
                const transformedData = data.map(item => ({
                    ...item,
                    coin: item.coin ?? 'unknown'
                }));
                setRows(transformedData);

                const coins = Array.from(new Set(transformedData.map(item => item.coin ?? 'unknown').filter(coin => coin !== '')));
                setUniqueCoins(coins);

                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching emitter hash list:', error);
                setError('Failed to fetch data.');
                setLoading(false);
            });
    };

    const handleCoinFilterChange = (event: SelectChangeEvent<string>) => {
        setCoinFilter(event.target.value);
    };

    const filteredRows = rows.filter(row =>
        coinFilter === '' || row.coin === coinFilter
    );

    const totalHashRate = filteredRows.reduce((total, row) => {
        return total + (hashType === 'hash15Min' ? row.hash15Min : row.hash24Hr);
    }, 0);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ color: '#888888' }} gutterBottom>
                        EMITTER HASH LIST
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <RadioGroup
                        row
                        value={hashType}
                        onChange={(e) => setHashType(e.target.value as 'hash15Min' | 'hash24Hr')}
                    >
                        <FormControlLabel value="hash15Min" control={<Radio />} label="15 Min Hash" />
                        <FormControlLabel value="hash24Hr" control={<Radio />} label="24 Hr Hash" />
                    </RadioGroup>

                    <Select
                        value={coinFilter}
                        onChange={handleCoinFilterChange}
                        displayEmpty
                        size='small'
                        sx={{ mr: '16px' }}
                    >
                        <MenuItem value="">
                            <em>All Coins</em>
                        </MenuItem>
                        {uniqueCoins.map((coin, index) => (
                            <MenuItem key={index} value={coin}>
                                {coin}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Total Hash Rate: {totalHashRate.toFixed(2)} TH/s
                            </Typography>
                            <TableContainer component={Paper} sx={{ padding: '16px' }}>
                                <Table sx={{ minWidth: 650 }} aria-label="emitter hash table">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'worker'}
                                                    direction={orderBy === 'worker' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('worker')}
                                                >
                                                    Worker Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === hashType}
                                                    direction={orderBy === hashType ? order : 'asc'}
                                                    onClick={() => handleRequestSort(hashType)}
                                                >
                                                    Hash Rate
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'coin'}
                                                    direction={orderBy === 'coin' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('coin')}
                                                >
                                                    Coin
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'status'}
                                                    direction={orderBy === 'status' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('status')}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.worker}
                                                </TableCell>
                                                <TableCell>
                                                    {hashType === 'hash15Min'
                                                        ? row.hash15Min.toFixed(2) + ' TH/s'
                                                        : row.hash24Hr.toFixed(2) + ' TH/s'}
                                                </TableCell>
                                                <TableCell>
                                                    {row.coin ?? 'unknown'}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        sx={{
                                                            color: row.status === 'ONLINE' ? 'green' : 'red',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {row.status}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
