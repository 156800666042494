import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    IconButton,
    Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableLoader from '../GlobalComponents/TableLoader';

interface Props { }

interface EmitterHash {
    worker: string;
    hash15Min: number;
    hash24Hr: number;
    status: 'ONLINE' | 'OFFLINE';
    coin: string | null;
    workerList: string[];
}

interface TransformedData {
    emitter: string;
    worker: string;
}

interface AccumulatedData {
    worker: string;
    emitters: string[];
    count: number;
}

function WorkerList(props: Props) {
    const [rows, setRows] = useState<TransformedData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchEmitterHashList();
    }, []);

    const fetchEmitterHashList = () => {
        setLoading(true);
        axios.get(API_PATH + '/monitor/emitterhash', getAxiosConfig())
            .then(response => {
                const data: EmitterHash[] = response.data;
                const transformedData = data.flatMap(item =>
                    item.workerList ? item.workerList.map(emitter => ({
                        worker: emitter,
                        emitter: item.worker
                    })) : []
                );
                setRows(transformedData);
            })
            .catch(error => {
                console.error('Error fetching emitter hash list:', error);
                setError('Failed to fetch data.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filteredRows = rows.filter(row => {
        const searchLower = searchTerm.trim().toLowerCase();
        return row.worker.trim().toLowerCase().includes(searchLower);
    });

    const reducedData = Object.values(
        filteredRows.reduce<{ [key: string]: AccumulatedData }>(
            (acc, current) => {
                const key = current.worker;
                if (!acc[key]) {
                    acc[key] = { worker: current.worker, emitters: [current.emitter], count: 1 };
                } else {
                    if (!acc[key].emitters.includes(current.emitter)) {
                        acc[key].emitters.push(current.emitter);
                    }
                    acc[key].count++;
                }
                return acc;
            },
            {}
        )
    );

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <TextField
                    size="small"
                    sx={{ width: "50%",mb:2 }}
                    label="Search"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </Box>
            {loading ? (
                <TableLoader/>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                                <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                    <TableCell align="center">Worker</TableCell>
                                <TableCell align="center">Emitters</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reducedData.length > 0 ? (
                                reducedData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.worker}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.emitters.join(', ')} {row.count > 1 && `X ${row.count}`}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        No matching results found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default WorkerList;