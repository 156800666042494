import * as React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import TableLoader from '../GlobalComponents/TableLoader';

interface hash {
    "worker": string,
    "redisHash": number,
    "f2PoolHash": number,
    "requiredHash": number
}

type HashKey = 'worker' | 'redisHash' | 'f2PoolHash' | 'requiredHash';

export default function HashList() {
    const [rows, setRows] = useState<hash[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('worker');

    const handleRequestSort = (property: HashKey) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array: hash[], comparator: (a: hash, b: hash) => number) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [hash, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order: "asc" | "desc", orderBy: string) => {
        return (a: hash, b: hash) => {
            // @ts-ignore
            if (b[orderBy] < a[orderBy]) {
                return order === 'desc' ? -1 : 1;
            }
            // @ts-ignore
            if (b[orderBy] > a[orderBy]) {
                return order === 'desc' ? 1 : -1;
            }
            return 0;
        };
    };






    useEffect(() => {
        fetchHashList();
    }, []);

    const fetchHashList = () => {
        axios.get(API_PATH + '/monitor/hash', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                console.log(response.data);

                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching machine list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };




    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        F2pool - Redis Hash
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <TableLoader />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="machine table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'worker'}
                                                direction={orderBy === 'worker' ? order : 'asc'}
                                                onClick={() => handleRequestSort('worker')}
                                            >
                                                Worker Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'redisHash'}
                                                direction={orderBy === 'redisHash' ? order : 'asc'}
                                                onClick={() => handleRequestSort('redisHash')}
                                            >
                                                Redis Hash
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'f2PoolHash'}
                                                direction={orderBy === 'f2PoolHash' ? order : 'asc'}
                                                onClick={() => handleRequestSort('f2PoolHash')}
                                            >
                                                F2pool Hash
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'requiredHash'}
                                                direction={orderBy === 'requiredHash' ? order : 'asc'}
                                                onClick={() => handleRequestSort('requiredHash')}
                                            >
                                                Required Hashrate
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.worker}
                                            </TableCell>
                                            <TableCell>{row.redisHash.toFixed(2) + " TH/s"}</TableCell>
                                            <TableCell>{row.f2PoolHash + " TH/s"} </TableCell>
                                            <TableCell>{row.requiredHash + " TH/s"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </Box>
    );


}
