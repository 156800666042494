import { Box, Button, Grid, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { useEffect, useState } from "react";
import { getAxiosConfig } from "../../../helpers/AuthHelper";
import TableLoader from "../GlobalComponents/TableLoader";
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface Account {
    userName: string;
}

interface RewardTable {
    account?: Account;
    coin: string;
}

interface TransactionData {
    rewardTables: RewardTable[];
    amount: number;
    transactionStatus: string;
    createdTime: string;
    networkTransactionId: string;
    rawError: string;
}

interface TableComponentProps {
    refreshData: boolean;
}

const PaymentHistoryTable: React.FC<TableComponentProps> = ({ refreshData }) => {
    const [fromDate, setFromDate] = useState<Date | null>(new Date());
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState(false);
    const [errorText, setError] = useState<string | null>(null);
    const [rows, setRows] = useState<TransactionData[]>([]);

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<string | null>(null);

    const handleOpenModal = (title: string, content: string | null) => {
        setModalTitle(title);
        setModalContent(content);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        searchData();
    }, [refreshData]);

    const searchData = () => {
        setLoading(true);

        const formattedFromDate = fromDate
            ? new Date(fromDate).getUTCFullYear() + "-" + (Number(new Date(fromDate).getUTCMonth()) + 1) + "-" + new Date(fromDate).getUTCDate()
            : null;
        const formattedToDate = toDate
            ? new Date(toDate).getUTCFullYear() + "-" + (Number(new Date(toDate).getUTCMonth()) + 1) + "-" + new Date(toDate).getUTCDate()
            : null;

        axios.get(`/api/segPoolRewardDistribution/transactions?from=${formattedFromDate}&to=${formattedToDate}`, getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching transaction data:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    function formatDateTime(isoDateString: string): string {
        const date = new Date(isoDateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} , ${hours}:${minutes}:${seconds}`;
    }

    return (
        <>
            <Grid container spacing={1} sx={{ margin: "10px", paddingX:'16px'}} justifyContent="flex-end" alignItems="center">
                <Grid item>
                    <Box textAlign="center">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select From Date"
                                value={fromDate}
                                format="dd/MM/yyyy"
                                slotProps={{ textField: { size: "small" } }}
                                onChange={(date: Date | null) => setFromDate(date)}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item>
                    <Box textAlign="center">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select To Date"
                                value={toDate}
                                format="dd/MM/yyyy"
                                slotProps={{ textField: { size: "small" } }}
                                onChange={(date: Date | null) => setToDate(date)}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item>
                    <Box textAlign="center">
                        <Button variant="contained" onClick={searchData} size="large">Search</Button>
                    </Box>
                </Grid>
            </Grid>

            <Paper sx={{padding:"16px"}}>
                {loading ?
                    <TableLoader /> :
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                <TableCell>User</TableCell>
                                <TableCell>Amount ($)</TableCell>
                                <TableCell>Coin</TableCell>
                                <TableCell>Transaction Status</TableCell>
                                <TableCell>Created Time</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box textAlign="center">No Data Available</Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {rows.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell>{detail?.rewardTables[0]?.account?.userName || "N/A"}</TableCell>
                                    <TableCell>{detail?.amount}</TableCell>
                                    <TableCell>{detail?.rewardTables[0]?.coin || "N/A"}</TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <span>{detail?.transactionStatus}</span>
                                            {detail?.networkTransactionId && (
                                                <InfoIcon
                                                    onClick={() => handleOpenModal("Network Transaction ID", detail?.networkTransactionId)}
                                                    sx={{ color: '#1977d3', mr: 1, cursor: 'pointer' }}
                                                />
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{formatDateTime(detail?.createdTime)}</TableCell>
                                    <TableCell>
                                        {detail?.rawError && (
                                            <WarningAmberIcon
                                                onClick={() => handleOpenModal("Error Details", detail?.rawError)}
                                                sx={{ color: '#FF0000', mr: 1, cursor: 'pointer' }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </Paper>

            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="dynamic-modal-title"
                aria-describedby="dynamic-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width:'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign:'center'
                    }}
                >
                    <h3
                        id="dynamic-modal-title"
                        style={{ color: modalTitle === "Error Details" ? 'red' : 'inherit' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {modalTitle === "Error Details"  && <WarningAmberIcon sx={{ color: '#FF0000', mr: 1 }} />}
                            {modalTitle}
                        </Box>
                    </h3>

                    <p
                        id="dynamic-modal-description"
                        style={{ color: modalTitle === "Error Details" ? 'red' : 'inherit' }}
                    >
                        {modalContent}
                    </p>
                    <Button
                        variant="contained"
                        onClick={handleCloseModal}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>


        </>
    );
};

export default PaymentHistoryTable;
