import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Typography, Tab, Tabs, Grid, Button } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import TableLoader from '../GlobalComponents/TableLoader';
import PaymentTable from './PaymentTable';
import { BalanceData, processTransactionList, TransactionData } from './PaymentTypes';
import PaymentHistoryTable from './PaymentHistoryTable';
import BalanceLoader from '../GlobalComponents/BalanceLoader';
import {RefreshRounded } from '@mui/icons-material';


export default function PaymentsList() {
    const [loading, setLoading] = useState<boolean>(true);
    const [balanceLoading, setBalanceLoading] = useState<boolean>(true); // Separate balance loading
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>("pending");
    const [rows, setRows] = useState<TransactionData[]>([]);
    const [balance, setBalance] = useState<BalanceData | null>(null); // BalanceData type

    useEffect(() => {
        fetchBalance();
        fetchPendingData();
    }, []);

    const fetchPendingData = () => {
        setLoading(true);
        axios.get('/api/segPoolRewardDistribution/pending', getAxiosConfig())
            .then(response => {
                setRows(processTransactionList(response.data));
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const fetchBalance = () => {
        setBalanceLoading(true);
        axios.get('/api/segPoolRewardDistribution/balance', getAxiosConfig())
            .then(response => {
                setBalance(response.data);
                setBalanceLoading(false);
            })
            .catch(error => {
                console.error("Error fetching balance:", error);
                setBalanceLoading(false);
            });
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        if (activeTab === "pending") {
            fetchPendingData();
        } else {
            setRows([]);
        }
    }, [activeTab]);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Box sx={{ width: '100%', padding: '16px' }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={2} md={1} display="flex" justifyContent="center">
                        <Button variant="contained" sx={{ padding: '16px', }} onClick={fetchBalance}>
                            <RefreshRounded />
                        </Button>
                    </Grid>
                    {balanceLoading ? (
                        <Grid item xs={11} >
                            <BalanceLoader />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        backgroundColor: '#f0f0f0',
                                        padding: '8px',
                                        borderRadius: '2px',
                                    }}
                                >
                                        <Typography sx={{ color: '#666666' }} variant="body2">
                                            Available Balance (KAS)
                                        </Typography>

                                    <Typography sx={{ color: '#1977d3', fontWeight: 'bold' }} variant="h6">{balance?.available}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        backgroundColor: '#f0f0f0',
                                        padding: '8px',
                                        borderRadius: '2px',
                                    }}
                                >
                                        <Typography sx={{ color: '#666666' }} variant="body2">Pending Balance (KAS)</Typography>
                                    <Typography sx={{ color: '#1977d3', fontWeight: 'bold' }} variant="h6">{balance?.pending}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        backgroundColor: '#f0f0f0',
                                        padding: '8px',
                                        borderRadius: '2px',
                                    }}
                                >
                                        <Typography sx={{ color: '#666666' }} variant="body2">Total Balance (KAS)</Typography>
                                    <Typography sx={{ color: '#1977d3', fontWeight: 'bold' }} variant="h6">{balance?.total}</Typography>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
            <Box>
                <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: "15px" }}>
                    <Tabs value={activeTab} onChange={handleChange} >
                        <Tab label="Pending Payments" value={"pending"} />
                        <Tab label="All Payments" value={"all"} />
                    </Tabs>
                </Box>
                {loading ? (
                    <TableLoader />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : activeTab === "pending" ? (
                    <PaymentTable rows={rows} refreshData={fetchPendingData} balance={balance} />
                ) : (
                    <PaymentHistoryTable refreshData={true} />
                )}
            </Box>
        </Box>
    );
}
